import React from 'react';
// import { Link } from 'react-router-dom';
// import IGlogo from '../resources/images/socials/instagram.svg';

function Footer() {
  return (
    <>
        <footer class="swatch-1">
            <div class="foot-container">
               { /*<h2>Shape tomorrow.</h2>*/}
                <br/><br/>
                {/*<ul class="foot-location">
                    <li>
                        <h3>Find us</h3>
                    </li>
                    <li><a href="" target="blank" class="foot-btn">Montreal</a></li>
                    <li><a href="" target="blank" class="foot-btn">Toronto</a></li>
                    <li><a href="" target="blank" class="foot-btn">Kelowna, BC</a></li>
                </ul>
                <ul class="foot-contact">
                    <li>
                        <h3>Reach us</h3>
                    </li>
                    <li>
                        <a class="foot-email a-alt foot-btn" href="mailto:info@showmetheramen.com?subject=Getting%20in%20Touch%20with%20Ramen%20Creative">email</a>
                    </li>
  </ul>*/}
                <h6 class="copyright">Copyright &copy; 2024 Okanagan International Film Festival. </h6>
            </div>
        </footer>
    {/* <footer className="swatch-2">
        <div className="foot-container">
            <ul className="foot-location">
                <h3>
                    <li>Find us</li>
                </h3>
                <h4 className="a-alt">
                    <li><Link to="" target="blank">Montreal</Link></li>
                    <li><Link to="" target="blank">Kelowna, BC</Link></li>
                </h4>
            </ul>
            <ul className="foot-contact">
                <h3>
                    <li>    
                        Reach us
                    </li>
                </h3>
                <h4 className="a-alt">
                    <li>
                        <a href="mailto:contact@khaoslabs.com?subject=Getting%20in%20Touch%20with%20Khaos%20Labs">contact@khaoslabs.com</a>
                    </li>
                </h4>
            </ul>
            <ul className="foot-social">
                <li>
                    <a href="https://www.instagram.com/khaoslabs/?hl=en">
                        <img src={IGlogo} alt="Instagram logo"/>
                    </a>
                </li>
            </ul>
            <h6 className="copyright">Copyright &copy; 2021 Create Khaos Labs Inc. </h6>
        </div>
    </footer> */}
    </>
  );
}

export default Footer;
