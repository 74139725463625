import React from 'react';
import { Link } from 'react-router-dom';

function Services() {
    return (
        <>
            <div className="box-set">
                <main className="index body-set">
                    <section className="intro swatch-1">
                        <main className="">
                        <h1>Call us
                        </h1>
                        <h2>
                            <div className="wrapper">
                                <br/>
                                <div className="words swatch-1">
                                    <span><a href="#ProductionStudio" className="a-alt2">Whatever You Like</a></span>
                                    <span><a href="#ProductionStudio" className="a-alt2">Content Creators</a></span>
                                    <span><a href="#CreationStudio" className="a-alt2">UX/UI Designers</a></span>
                                    <span><a href="#CreationStudio" className="a-alt2">Graphic Designers</a></span>
                                    <span><a href="#StrategicPartner" className="a-alt2">Consultants</a></span>
                                    <span><a href="#CreationStudio" className="a-alt2">Web Developers</a></span>
                                    <span><a href="#ProductionStudio" className="a-alt2">Whatever You Like</a></span>
                                </div>
                            </div>
                        </h2>
                        <h3>We care about solutions<br/>not labels...</h3>
                        </main>
                    </section>
                    {/*
                    <section className="body-section swatch-2">
                        <h2>
                            Some of the names others have used for us...
                        </h2> <br/>
                        <h4 className="font-1 non-em a-alt4">
                            <ul>
                                <li><a className="btn" href="#ProductionStudio">Content Creators</a></li>
                                <li><a className="btn" href="#MarketingAgency">Marketing agency</a></li>
                                <li><a className="btn" href="#CreationStudio">Graphic Designers</a></li>
                                <li><a className="btn" href="#StrategicPartner">Consultants</a></li>
                            </ul>
                        </h4>  
                        <br/>
                        <h2>
                            Pick one to Find out More.
                        </h2>
                    </section>*/}
                    <section className="body-section definition2 swatch-2 odd">
                        <br/><br/>
                        <section id="ProductionStudio">
                            <h3>Content Creators</h3><br/>
                            <p>This is where we got our start and we spun out our own film production, <a href="httsp://khaoslabs.com">Khaos Labs</a>.</p>
                            <ul>
                                <li>Video Production</li>
                                <li>Photography</li>
                                <li>Website Development</li>
                            </ul>
                        </section>
                        <section className="odd-client" id="MarketingAgency">
                            <h3>Marketing Agency</h3><br/>
                            <p>Digital natives who craft powerful communities with a subtle touch. </p>
                            <ul>
                                <li>Branding Strategy</li>
                                <li>Social media Management</li>
                                <li>Social Media Clips</li>
                            </ul>
                        </section>
                        <section id="CreationStudio">
                            <h3>Graphic Designers</h3><br/>
                            <p>Experience designers who obsess over your user’s daily delight.</p>
                            <ul>
                                <li>Graphic design</li>
                                <li>Industrial design</li>
                                <li>User experience/interface</li>
                            </ul>
                        </section>
                        <section className="odd-client" id="StrategicPartner"> 
                        <h3>Consultants</h3><br/>
                            <p>Business experts who analyze today’s constraints to predict tomorrow’s opportunities.</p>
                            <ul>
                                <li>User research </li>
                                <li>Project management</li>
                                <li>Boutique consultation</li>
                            </ul>
                        </section>
                    </section>
                   {/* <section className="featured-work body-section definition2">
                        <h5>Featured Work</h5>
                        <h3>2020: A year of Khaos</h3>
                        <p>Launching at the height of Covid-19 brings new challenges to the old movie circuit.</p>
                    </section> */}
                    <aside className="body-section swatch-4">
                        <h3 className="non-em" >
                            Start hitting <strong>milestones</strong><br/>instead of <strong>dead zones</strong>
                        <br/><br/>
                            <Link to="/contact" className="btn btn4">Get in touch</Link>
                        </h3>
                    </aside>
                </main>
            </div>
        </>
    )
}

export default Services;