import React from 'react';
import { Link } from 'react-router-dom';
import CaseStudies from '../Database/CaseStudies';


function Home() {
    const Clients = [];
    let ClientSkills = [];
    for (const cs of CaseStudies) {
        ClientSkills = [];
        for (const skills of cs.SkillSet) {
            ClientSkills.push(
                <li>{skills}</li>
            )
        }
        if (Clients.length < 3) {
            Clients.push(
                <>
                <section className=
                {Clients.length % 2 === 1 ? "odd-client": ""}
                >
                    <h4>{cs.Client}</h4>
                    <h3>{cs.Campaign}</h3>
                    <h5>
                        <ul>
                            {ClientSkills}
                        </ul>
                    </h5>
                    <h5 className="btn2">
                        <Link to={`/case-studies/${cs.cSName}`} >Learn More
                        </Link>
                    </h5>
                </section><br/>
            </>
            )
        }
    }
    
    return (
        <>
            <div className="box-set swatch">
                <main className="index body-set">
                <section className="intro swatch-2">
                    <h2>
                        We are the Okangan International Film Festival
                    </h2>
                    <br/><br/>
                    <p>We are in the process of organizing screenings of independent films around the okangan, and organizing a 3-day film festival for June 2025</p>
                    <br/>
                    <p>We'll be sharing more information through this as it becomes availabe</p>
                    <br/>
                    <p>If you have a question in the meantime or want to get involved as we build, feel free to email us at <a href="mailto:contact@okiff.ca?subject=Getting%20in%20Touch%20with%20OKIFF">contact@okiff.ca</a></p>
                    </section>
                    {/*
                    <section className="intro swatch-1">
                        <main className="">
                            <h1 className="">A Multi-Disciplinary Creative Agency</h1>
                            <h3>We’re the creative agency helping brands tell their story.</h3>
                            <br/>
                            <h3 className="word-break">
                                <Link to="/Services" className="btn">
                                    Here’s how...
                                </Link>
                            </h3>
                        </main>    
                    </section>
                    <section className="body-section  brands-index swatch-2 odd">
                        <main className="brands">
                                <h3 className="past-clients">Over 100 clients and counting</h3>
                                <br/>
                                <div className="past-clients">
                                    <img src={ArtStationLogo} alt="Art Station Logo" srcset="" className="past-clients-img"/>
                                    <img src={SonderLogo} alt="Sonder Logo" srcset="" className="past-clients-img"/>
                                    <img src={HopperLogo} alt="Hopper Logo" srcset="" className="past-clients-img"/>
                                    <img src={VOTLogo} alt="" srcset="" className="past-clients-img"/>
                                    <img src={LabsLogo} alt="" srcset="" className="past-clients-img"/>
                                    <img src={ShowdownLogo} alt="" srcset="" className="past-clients-img"/>
                                    <img src={YPCLogo} alt="" srcset="" className="past-clients-img"/>
                                </div>
                                <div className="line-break flip"></div>
                    </main>    
                        <aside className="">
                            <h3 className="">We’ve been there before.</h3>
                            <br/>
                            <p>
                                We’ve worked with large corporations and NGOs, to tech startups to local small businesses across the North America and the UK.
                                <br/><br/>
                                We are the marketing experts supporting passionate teams—because it always helps to have someone who’s been there and done that.
                            </p>
                        </aside>
                    </section> 
                    <section className="past-work body-section swatch-4 odd">
                            <h2>Case Studies Coming Soon</h2>
    
    {/*
                            <h2>Our Past Work</h2>
                            <br/><br/><br/>
    {Clients}
                    </section>
    <CallToAction />*/}
                </main>
            </div>
        </>
    )
}

export default Home;